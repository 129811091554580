import { useParams } from "@solidjs/router";
import {
  createSignal,
  ErrorBoundary,
  Match,
  onMount,
  Show,
  Suspense,
  Switch,
} from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";

import { HubbleError } from "~/components/error";
import { GiftBoxFallback } from "~/components/gifting/gift-box/fallback_states";
import { GiftExpired } from "~/components/gifting/gift-box/gift_expired";
import { GiftRevealSection } from "~/components/gifting/gift-box/gift_reveal_section";
import { GiftRoot } from "~/components/gifting/gift_root";
import { HubbleEvent, webEventManager } from "~/data/events";
import { getGiftDetailsMinimalResponse } from "~/server/apis/client_apis";
import {
  DeliveryMethod,
  GiftAuthMethod,
  GiftDetailsMinimalResponse,
} from "~/server/types/gift";
import { Cookie } from "~/types";
import { getCookie, setCookie } from "~/utils/client_cookie";
import { generateDeviceVerificationToken } from "~/utils/common";
import { formatDateLong } from "~/utils/date";
import { createJob } from "~/utils/job";
import attachPopStateListener from "~/utils/popstate_listener";
import { captureErrorInSentry } from "~/utils/third_party/sentry";

export type GiftBoxRouteData = {
  giftDetailsMinimalResponse: GiftDetailsMinimalResponse;
  deviceVerificationToken: string | undefined;
};

const getGiftBoxRouteData = async (key: string): Promise<GiftBoxRouteData> => {
  let deviceVerificationToken: string | undefined = undefined;
  try {
    deviceVerificationToken = await generateDeviceVerificationToken();
  } catch (error) {
    console.log("Error getting device verification token", error);
  }

  return {
    deviceVerificationToken: deviceVerificationToken,
    giftDetailsMinimalResponse: await getGiftDetailsMinimalResponse(
      key,
      deviceVerificationToken
    ),
  };
};

export default function Gift() {
  const params = useParams();
  const giftingKey = params.giftingKey;

  const fetchGiftBoxRouteJob = createJob<GiftBoxRouteData>({
    initialJob: () => {
      return getGiftBoxRouteData(giftingKey);
    },
    errorCallback: (error) => {
      setErrorMessage(error.message);
    },
    successCallback: async (response) => {
      setErrorMessage(undefined);
      if (response.giftDetailsMinimalResponse.status === "NOT_ACTIVATED") {
        window.location.href = `/buy/${response.giftDetailsMinimalResponse.productMetadata.brandId}`;
      }
    },
  });

  onMount(() => {
    fetchGiftBoxRouteJob.run();
    attachPopStateListener();
    webEventManager.sendEvent(HubbleEvent.SCAN_GIFT_BOX);
  });

  const routeData = fetchGiftBoxRouteJob.jobResult;

  const [showExpiryOnBottom, setShowExpiryOnBottom] = createSignal(false);
  const [isGiftUnwrapAnimationComplete, setIsGiftUnwrapAnimationComplete] =
    createSignal(false);
  const [errorMessage, setErrorMessage] = createSignal<string | undefined>();

  return (
    <>
      <ErrorBoundary
        fallback={(err) => {
          captureErrorInSentry(err);
          return <HubbleError errorMessage={err.message} />;
        }}
      >
        <Suspense>
          <script src="https://unpkg.com/@lottiefiles/lottie-player@0.3.0/dist/lottie-player.js"></script>
          <ClientOnlyComponent component={ClientComponent.SnackbarHost} />
          <ClientOnlyComponent component={ClientComponent.ModalHost} />
          <Show
            when={routeData()}
            fallback={
              <GiftBoxFallback
                fetchGiftBoxRouteJob={fetchGiftBoxRouteJob}
                errorMessage={errorMessage}
              />
            }
          >
            <GiftBox data={routeData()!} />
          </Show>
        </Suspense>
      </ErrorBoundary>
    </>
  );

  function GiftBox({ data }: { data: GiftBoxRouteData }) {
    onMount(() => {
      setShowExpiryOnBottom(true);
    });

    const eventParams = defaultEventProps(data);

    return (
      <>
        <Switch>
          <Match when={data.giftDetailsMinimalResponse.status !== "EXPIRED"}>
            <GiftRoot
              isPreview={false}
              showGuidelines={false}
              brand={data.giftDetailsMinimalResponse.productMetadata}
              amount={data.giftDetailsMinimalResponse.amount}
              description={data.giftDetailsMinimalResponse.description}
              getGiftVoucherSectionComponent={getGiftVoucherSectionComponent}
              tncSubtitle={getTncSubtitle()}
              onClickUnwrapGift={() => {
                setShowExpiryOnBottom(false);
              }}
              onUnwrapAnimationComplete={() => {
                setIsGiftUnwrapAnimationComplete(true);
              }}
              faqs={data.giftDetailsMinimalResponse.faqs}
              defaultEventProps={eventParams}
            />
            <ExpiryDate />
          </Match>
          <Match when={data.giftDetailsMinimalResponse.status === "EXPIRED"}>
            <GiftExpired
              brand={data.giftDetailsMinimalResponse.productMetadata}
              amount={data.giftDetailsMinimalResponse.amount}
              getGiftVoucherSectionComponent={getGiftVoucherSectionComponent}
              expiryDate={data.giftDetailsMinimalResponse.expiry}
            />
          </Match>
        </Switch>
      </>
    );

    function getGiftVoucherSectionComponent() {
      return (
        <GiftRevealSection
          status={
            routeData()?.giftDetailsMinimalResponse?.status ?? "ACTIVATED"
          }
          deliveryMethod={getDeliveryMethod()}
          giftAuthMethod={getAuthMethod()}
          giftId={giftingKey}
          brand={routeData()!.giftDetailsMinimalResponse!.productMetadata}
          isGiftUnwrapAnimationComplete={isGiftUnwrapAnimationComplete}
          identifier={
            routeData()!.giftDetailsMinimalResponse!.customer?.phoneNumber ??
            undefined
          }
          isAuthRequired={
            routeData()?.giftDetailsMinimalResponse?.isAuthRequired ?? true
          }
          retryAvailable={
            routeData()?.giftDetailsMinimalResponse?.delivery?.retryAvailable ??
            false
          }
          generateDeviceVerificationToken={generateDeviceVerificationToken}
          deviceVerificationToken={routeData()!.deviceVerificationToken}
          defaultEventProps={eventParams}
        />
      );
    }
  }

  function getAuthMethod(): GiftAuthMethod {
    return (
      routeData()?.giftDetailsMinimalResponse?.authMethods || ["NONE"]
    ).at(0)!;
  }

  function getDeliveryMethod(): DeliveryMethod {
    return (
      routeData()?.giftDetailsMinimalResponse?.deliveryMethods || ["WHATSAPP"]
    ).at(0)!;
  }

  function getTncSubtitle(): string {
    return getDeliveryMethod() === "WHATSAPP"
      ? "Applicable after the card details are sent to WhatsApp"
      : "Applicable once card details are revealed for the first time";
  }

  function ExpiryDate() {
    return (
      <Show when={routeData()?.giftDetailsMinimalResponse.expiry}>
        <div class="fixed bottom-4 left-0 right-0 flex justify-center ">
          <span
            classList={{
              "translate-y-20": !showExpiryOnBottom(),
            }}
            class="text-f12 text-white duration-300"
          >{`Link expires on ${formatDateLong(new Date(routeData()?.giftDetailsMinimalResponse.expiry!))}`}</span>
        </div>
      </Show>
    );
  }

  function defaultEventProps(routeData: GiftBoxRouteData): Record<string, any> {
    const giftResponse = routeData.giftDetailsMinimalResponse;
    return {
      deviceVerificationToken: routeData.deviceVerificationToken,
      giftMode: "GIFT_BOX",
      isPreview: false,

      // dynamic props
      status: giftResponse.status,
      deliveryRetryAvailable: giftResponse.delivery?.retryAvailable,
      customer: giftResponse.customer,
      isIdentifierLinked: !!giftResponse.customer?.phoneNumber,
      identifier: giftResponse.customer?.phoneNumber,
      isAuthRequired: giftResponse.isAuthRequired,

      // static props
      authMethod: getAuthMethod(),
      deliveryMethod: getDeliveryMethod(),
      serialNo: giftResponse.serialNo,
      brandId: giftResponse.productMetadata.brandId,
      brandKey: giftResponse.productMetadata.brandKey,
      brandName: giftResponse.productMetadata.brandName,
      dateOfManufacture: giftResponse.dateOfManufacture,
      expiry: giftResponse.expiry,
      amount: giftResponse.amount,
      $current_url: "/gift-box",
    };
  }
}
